import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import H4 from '@rotaready/frecl/build/H4';

import { ViewportBreakpoints } from '../cssConstants';

import ResponsiveContainer from '../layout/ResponsiveContainer';
import RelativeWrapper from '../layout/RelativeWrapper';
import TextHeadingWrapper from '../layout/TextHeadingWrapper';

const Logos = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin-bottom: 0;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  animation: marquee 30s linear infinite;

  @keyframes marquee {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translatex(-${props => (props.logoCount * 200)}px);
    }
  }
`;

const Logo = styled.div`
  flex: 0 0 100px;
  margin: 0 50px;
  text-align: center;
  padding: 0 8px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 0;
  }

  > div {
    margin: 0 auto;
  }
`;

export const ClientLogos = Object.freeze({
  BATHSTORE: {
    id: 'bathstore',
    maxWidth: 65,
    alt: 'Bathstore',
  },
  BREWDOG: {
    id: 'brewdog',
    maxWidth: 145,
    alt: 'BrewDog',
  },
  CAMDEN_TOWN_BREWERY: {
    id: 'camdenTownBrewery',
    maxWidth: 75,
    alt: 'Camden Town Brewery',
  },
  CAMINO: {
    id: 'camino',
    maxWidth: 100,
    alt: 'Camino',
  },
  EASYCOFFEE: {
    id: 'easyCoffee',
    maxWidth: 65,
    alt: 'Easycoffee',
  },
  EE: {
    id: 'ee',
    maxWidth: 65,
    alt: 'EE',
  },
  HICKORYS_SMOKEHOUSE: {
    id: 'hickorysSmokehouse',
    maxWidth: 105,
    alt: 'Hickorys Smokehouse',
  },
  HOUSE_CAFE_COMPANY: {
    id: 'houseCafeCompany',
    maxWidth: 100,
    alt: 'House Cafe Company',
  },
  MANORVIEW_HOTELS: {
    id: 'manorviewHotels',
    maxWidth: 130,
    alt: 'Manorview Hotels',
  },
  MARCUS_WAREING: {
    id: 'marcusWareing',
    maxWidth: 50,
    alt: 'Marcus Wareing',
  },
  MARK_SARGEANT: {
    id: 'markSargeant',
    maxWidth: 120,
    alt: 'Mark Sargeant',
  },
  NAMCO: {
    id: 'namco',
    maxWidth: 80,
    alt: 'Namco',
  },
  NEW_FOREST_HOTELS: {
    id: 'newForestHotels',
    maxWidth: 120,
    alt: 'New Forest Hotels',
  },
  O2: {
    id: 'o2',
    maxWidth: 60,
    alt: 'O2',
  },
  OUTDOOR_AND_COUNTRY: {
    id: 'outdoorAndCountry',
    maxWidth: 65,
    alt: 'Outdoor and Country',
  },
  PANDORA: {
    id: 'pandora',
    maxWidth: 155,
    alt: 'Pandora',
  },
  PIZZA_PILGRIMS: {
    id: 'pizzaPilgrims',
    maxWidth: 110,
    alt: 'Pizza Pilgrims',
  },
  STORMFRONT: {
    id: 'stormfront',
    maxWidth: 90,
    alt: 'Stormfront (Apple Store)',
  },
  TOMMIS_BURGER_JOINT: {
    id: 'tommisBurgerJoint',
    maxWidth: 140,
    alt: 'Tommis Burger Joint',
  },
  TOPGOLF: {
    id: 'topgolf',
    maxWidth: 80,
    alt: 'Topgolf',
  },
  WARNER_LEISURE_HOTELS: {
    id: 'warnerLeisureHotels',
    maxWidth: 135,
    alt: 'Warner Leisure Hotels',
  },
  CREAMS: {
    id: 'creams',
    maxWidth: 90,
    alt: 'Creams',
  },
  DISHOOM: {
    id: 'dishoom',
    maxWidth: 135,
    alt: 'Dishoom',
  },
  GUSTO: {
    id: 'gusto',
    maxWidth: 155,
    alt: 'Gusto Italian',
  },
  HUBBOX: {
    id: 'hubbox',
    maxWidth: 135,
    alt: 'Hub Box',
  },
  MOLLIES: {
    id: 'mollies',
    maxWidth: 135,
    alt: 'Mollie\'s Motel and Diner',
  },
  NQ64: {
    id: 'nq64',
    maxWidth: 125,
    alt: 'NQ64 Arcade',
  },
  SUPERBOWL: {
    id: 'superbowl',
    maxWidth: 125,
    alt: 'Superbowl UK',
  },
});

const ClientLogoBar = ({
  logos,
  withWrapper,
}) => (
  <StaticQuery
    query={graphql`
      query {
        bathstore: file(relativePath: { eq: "client-logos/bathstore.jpg" }) {
          childImageSharp {
            gatsbyImageData(quality:100)
          }
        }

        brewdog: file(relativePath: { eq: "client-logos/brewdog.png" }) {
          childImageSharp {
            gatsbyImageData(quality:100)
          }
        }

        camdenTownBrewery: file(relativePath: { eq: "client-logos/camden-town-brewery.jpg" }) {
          childImageSharp {
            gatsbyImageData(quality:100)
          }
        }

        camino: file(relativePath: { eq: "client-logos/camino.jpg" }) {
          childImageSharp {
            gatsbyImageData(quality:100)
          }
        }

        easyCoffee: file(relativePath: { eq: "client-logos/easycoffee.jpg" }) {
          childImageSharp {
            gatsbyImageData(quality:100)
          }
        }

        ee: file(relativePath: { eq: "client-logos/ee.jpg" }) {
          childImageSharp {
            gatsbyImageData(quality:100)
          }
        }

        hickorysSmokehouse: file(relativePath: { eq: "client-logos/hickorys-smokehouse.jpg" }) {
          childImageSharp {
            gatsbyImageData(quality:100)
          }
        }

        houseCafeCompany: file(relativePath: { eq: "client-logos/house-cafe-company.jpg" }) {
          childImageSharp {
            gatsbyImageData(quality:100)
          }
        }

        manorviewHotels: file(relativePath: { eq: "client-logos/manorview-hotels.jpg" }) {
          childImageSharp {
            gatsbyImageData(quality:100)
          }
        }

        marcusWareing: file(relativePath: { eq: "client-logos/marcus-wareing.jpg" }) {
          childImageSharp {
            gatsbyImageData(quality:100)
          }
        }

        markSargeant: file(relativePath: { eq: "client-logos/mark-sargeant.jpg" }) {
          childImageSharp {
            gatsbyImageData(quality:100)
          }
        }

        namco: file(relativePath: { eq: "client-logos/namco.webp" }) {
          childImageSharp {
            gatsbyImageData(quality:100)
          }
        }

        newForestHotels: file(relativePath: { eq: "client-logos/new-forest-hotels.jpg" }) {
          childImageSharp {
            gatsbyImageData(quality:100)
          }
        }

        o2: file(relativePath: { eq: "client-logos/o2.jpg" }) {
          childImageSharp {
            gatsbyImageData(quality:100)
          }
        }

        outdoorAndCountry: file(relativePath: { eq: "client-logos/outdoor-and-country.jpg" }) {
          childImageSharp {
            gatsbyImageData(quality:100)
          }
        }

        pandora: file(relativePath: { eq: "client-logos/pandora.jpg" }) {
          childImageSharp {
            gatsbyImageData(quality:100)
          }
        }

        pizzaPilgrims: file(relativePath: { eq: "client-logos/pizza-pilgrims.png" }) {
          childImageSharp {
            gatsbyImageData(quality:100)
          }
        }

        stormfront: file(relativePath: { eq: "client-logos/stormfront-square.jpg" }) {
          childImageSharp {
            gatsbyImageData(quality:100)
          }
        }

        tommisBurgerJoint: file(relativePath: { eq: "client-logos/tommis-burger-joint.jpg" }) {
          childImageSharp {
            gatsbyImageData(quality:100)
          }
        }

        topgolf: file(relativePath: { eq: "client-logos/topgolf.jpg" }) {
          childImageSharp {
            gatsbyImageData(quality:100)
          }
        }

        warnerLeisureHotels: file(relativePath: { eq: "client-logos/warner-leisure-hotels.jpg" }) {
          childImageSharp {
            gatsbyImageData(quality:100)
          }
        }

        creams: file(relativePath: { eq: "client-logos/creams.png" }) {
          childImageSharp {
            gatsbyImageData(quality:100)
          }
        }
        dishoom: file(relativePath: { eq: "client-logos/dishoom.png" }) {
          childImageSharp {
            gatsbyImageData(quality:100)
          }
        }
        gusto: file(relativePath: { eq: "client-logos/gusto.png" }) {
          childImageSharp {
            gatsbyImageData(quality:100)
          }
        }
        hubbox: file(relativePath: { eq: "client-logos/hubbox.png" }) {
          childImageSharp {
            gatsbyImageData(quality:100)
          }
        }
        mollies: file(relativePath: { eq: "client-logos/mollies.png" }) {
          childImageSharp {
            gatsbyImageData(quality:100)
          }
        }
        nq64: file(relativePath: { eq: "client-logos/nq64.png" }) {
          childImageSharp {
            gatsbyImageData(quality:100)
          }
        }
        superbowl: file(relativePath: { eq: "client-logos/superbowl-uk.png" }) {
          childImageSharp {
            gatsbyImageData(quality:100)
          }
        }
      }
    `}
    render={data => (
      withWrapper ? (
        <RelativeWrapper backgroundColor="white">
          <ResponsiveContainer>
            <Logos>
              <TextHeadingWrapper>
                <H4 uistyle="brand160">Trusted by</H4>
              </TextHeadingWrapper>

              <LogoWrapper logoCount={logos.length}>
                {Array.from({ length: 2 }, () => logos).flat().map(logo => (
                  <Logo key={logo.id}>
                    <GatsbyImage
                      image={data[logo.id].childImageSharp.gatsbyImageData}
                      alt={logo.alt}
                    />
                  </Logo>
                ))}
              </LogoWrapper>
            </Logos>
          </ResponsiveContainer>
        </RelativeWrapper>
      ) : (
        logos.map(logo => (
          <Logo key={logo.id}>
            <div style={{ maxWidth: `${logo.maxWidth}px` }}>
              <GatsbyImage
                image={data[logo.id].childImageSharp.gatsbyImageData}
                alt={logo.alt}
              />
            </div>
          </Logo>
        ))
      )
    )}
  />
);

ClientLogoBar.defaultProps = {
  withWrapper: true,
};

ClientLogoBar.propTypes = {
  logos: PropTypes.arrayOf(PropTypes.string).isRequired,
  withWrapper: PropTypes.bool,
};

export default ClientLogoBar;
